import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const MixPage = lazy(() => import('@/containers/Views/Mixes/MixPage'));
const NewMixPage = lazy(() => import('@/containers/Views/Mixes/NewMixPage'));
const EditMixPage = lazy(() => import('@/containers/Views/Mixes/EditMixPage'));
const MixRelations = lazy(() => import('@/containers/Views/Mixes/MixRelations'));
const NotFound = lazy(() => import('@/shared/components/NotFound'));

const MixesRouter = (
  <Route path="mixes">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Mixes} 
          permissions={EBasicActions.Find}
        >
          <MixPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.Mixes} 
          permissions={EBasicActions.Create}
        >
          <NewMixPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/edit"
      element={(
        <PrivateRoute 
          pageName={EPage.Mixes} 
          permissions={EBasicActions.Edit}
        >
          <EditMixPage />
        </PrivateRoute>
      )}
    />
    <Route
      path=":id/relations"
      element={(
        <PrivateRoute 
          pageName={EPage.Mixes} 
          permissions={EBasicActions.Edit}
        >
          <MixRelations />
        </PrivateRoute>
      )}
    />
    <Route 
      path="not_found"
      element={(
        <PrivateRoute 
          pageName={EPage.Mixes} 
          permissions={EBasicActions.Find}
        >
          <NotFound scope="mix" />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default MixesRouter;
